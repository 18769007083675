import {Storage} from "@aws-amplify/storage";

export function getLogo(logo, success) {
  Storage.get(`logos/${logo}`, {
    level: 'public',
  }).then(
    response => success && success(response),
    err => console.log("err", err),
  );
}

export async function updateLogo(logoName, logoContent, logoType, success, failure) {
  await Storage.put(
    'logos/'+logoName,
    logoContent,
    {
      level: "public",
      contentType: logoType,
    }
  )
}

export async function deleteLogo (organizationId, logoType) {
  await Storage.remove(
    'logos/'+organizationId+logoType,
    {
      level: "public",
      contentType: logoType,
    }
  )
}

export async function getCertificateFiles(certificateId, files, success) {
  const result = []
  await Promise.all(files?.map(async file => {
    let name = file;
    let path;
    if(typeof file !== "string" && file.isAcord25) {
      name = file.name;
      path = `${certificateId}/acord25/${name}`;
    } else {
      path = `${certificateId}/${name}`
    }
    const content = await Storage.get(path, {
      level: 'protected',
      identityId: 'certificates'
    })
    result.push({
      name,
      content,
      isAcord25: file.isAcord25
    })
    return Promise.resolve()
  }))
  success && success(result)
  return result;
}

export async function uploadCertificateFile(certificateId, identityId, fileName, fileContent, scanId) {
  const config = {
    level: "protected",
    contentType: "application/pdf",
    contentDisposition: "attachment",
  }
  config.identityId = identityId
  const filePath = (identityId === 'scanning') ? `input/${certificateId}/${scanId}/${fileName}` : `${certificateId}/${fileName}`
  const fetched = await fetch(fileContent)
  const blobbed = await fetched.blob()
  try {
    const result = await Storage.put(filePath, blobbed, config)
    return result
  } catch(e) {
    console.log("e", e)
  }
}


export async function uploadUsingPresignedUrl(preSignedUrl, fileContent) {
  const fetched = await fetch(fileContent)
  const blobbed = await fetched.blob()
  const result = await fetch(preSignedUrl, {
    method: "PUT",
    body: blobbed,
  })
  return result
}

export async function copyScannedAcord25(certificateId, scanId, fileName) {
  await Storage.copy(
    { // src
      key: `input/${certificateId}/${scanId}/${fileName}`,
      level: 'protected',
      identityId: 'scanning',
    },
    { // dest
      key: `${certificateId}/acord25/${fileName}`,
      level: "protected",
    },
    { // config
      level: "protected",
      identityId: "certificates",
      contentType: "application/pdf",
      contentDisposition: "attachment",
    }
  )
}

export async function deleteCertificateFile(certificateId, fileName) {
  const result = await Storage.remove(`${certificateId}/${fileName}`, {
    level: 'protected',
    identityId: 'certificates'
  })
  return result
}

export async function deleteCertificateFromS3 (certificateId) {

  //remove all files from /protected/certificates
  const allObjectsInCertificates = await Storage.list(`${certificateId}/`, {
    level: 'protected',
    identityId: 'certificates'
  })
  
  await Promise.all(allObjectsInCertificates?.map(async file => {
    await Storage.remove(file.key, {
        level: 'protected',
        identityId: 'certificates'
      })
      return Promise.resolve();
    }))
    
  //remove all files from /protected/scanning/input
  const allObjectsInScanningInput = await Storage.list(`${certificateId}/`, {
    level: 'protected',
    identityId: 'scanning/input'
  })
  
  await Promise.all(allObjectsInScanningInput?.map(async file => {
    await Storage.remove(file.key, {
      level: 'protected',
      identityId: 'scanning/input'
    })
    return Promise.resolve();
  }))
  
  //remove all files from /protected/scanning/output
  const allObjectsInScanningOutput = await Storage.list(`${certificateId}/`, {
    level: 'protected',
    identityId: 'scanning/output'
  })

  await Promise.all(allObjectsInScanningOutput?.map(async file => {
    await Storage.remove(file.key, {
        level: 'protected',
        identityId: 'scanning/output'
    })
    return Promise.resolve();
  }))
}
