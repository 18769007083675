import {isExpired, awsDateToUS} from "./dateUtils"
import {non_custom_insurance_types} from "constants/insurance_types"
import {formatCurrencyInt} from "./formUtils"

const notBlankFields = [
  "certificateDate",
  "insured",
  "certificateHolder",
  "producer",
  "producerContactName",
  "producerPhone",
  // "producerFax",
  "producerEmail",
]

const fieldKeyLabel = {
  certificateDate: "Certificate Date",
  insured: "Insured",
  certificateHolder: "Certificate Holder",
  producer: "Producer",
  producerContactName: "Producer Contact Name",
  producerPhone: "Producer Phone",
  producerEmail: "Producer Email",
  GL: "COMMERCIAL GENERAL LIABILITY",
  AU: "AUTOMOBILE LIABILITY",
  UMB: "UMBRELLA LIAB/EXCESS LIAB",
  WC: "WORKERS COMPENSATION AND EMPLOYERS' LIABILITY",
  insuranceForm: "Insurance Form",
  aggregateAppliesPer: "Aggregate Applies Per",
  coveredProperty: "Properties",
  selectedCoverage: "Coverages",
  additionalInsured: "Additional Insured",
  subrogationWaiver: "Subrogation Waiver",
  deductible: "Deductible",
  retention: "Retention",
  limitPerStatute: "Limit Per Statute",
  exclusion: "Exclusion",
}

const insurers = [
  ["insurerA", "insurerANAIC"],
  ["insurerB", "insurerBNAIC"],
  ["insurerC", "insurerCNAIC"],
  ["insurerD", "insurerDNAIC"],
  ["insurerE", "insurerENAIC"],
  ["insurerF", "insurerFNAIC"],
]

const coverageArrayFields = [
  "insuranceForm",
  "aggregateAppliesPer",
  "coveredProperty",
  "selectedCoverage",
]

const coverageMatchFields = [
  "additionalInsured",
  "subrogationWaiver",
  "deductible",
  "retention",
  "limitPerStatute",
  "exclusion",
]

export function validate(certData = {}, references = []) {
  let validationFailed = false;
  let failedReasons = [];
  let hasNameAdditionalInsured = false;
  if(typeof references === "string") {
    references = JSON.parse(references)
  }

  // validate overview
  notBlankFields.forEach(fieldKey => {
    if(!String(certData[fieldKey]).trim()) {
      validationFailed = true;
      failedReasons.push(fieldKeyLabel[fieldKey]  + " cannot be empty.")
    }
  })

  const hasOneInsurer = insurers.find(([insurer, insurerNAIC]) => (certData[insurer] && certData[insurerNAIC]))
  if(!hasOneInsurer) {
    validationFailed = true;
    failedReasons.push("At least one insurer and NAIC required.")
  }

  // validate coverage
  if(references?.length) {
    references.forEach(reference => {
      const { insuranceType } = reference
      // for now, validate only non-custom types
      if(non_custom_insurance_types.includes(insuranceType)) {
        const coverage = certData?.coverages?.items.find(coverage => coverage.insuranceType === insuranceType)
        if(!coverage) {
          validationFailed = true;
          failedReasons.push(`${fieldKeyLabel[insuranceType]} is missing.`)
        } else {

          // begin - process fields that needs to match
          coverageArrayFields.forEach(fieldKey => {
            if(reference[fieldKey]?.length) {
              const toMatch = [...reference[fieldKey]].filter(fieldValue => !!fieldValue)
              const fieldValue = coverage[fieldKey]?.length ? [...coverage[fieldKey]].filter(fieldValue => !!String(fieldValue).trim()) : []
              if(
                fieldValue.map(value => String(value).toLowerCase()).sort().join(",") !== toMatch.map(value => String(value).toLowerCase()).sort().join(","))
              {
                const error = fieldValue?.length ? `got ${fieldValue.join(", ")}` : "none selected"

                validationFailed = true;
                failedReasons.push(
                  `${fieldKeyLabel[insuranceType]} does not meet requirement - `
                  +`${fieldKeyLabel[fieldKey]} requires ${toMatch.join(", ").replaceAll("_", "-")} but ${error}.`
                )
              }
            }
          })
          coverageMatchFields.forEach(fieldKey => {
            if(reference[fieldKey] && coverage[fieldKey] !== reference[fieldKey]) {
              const error = (reference[fieldKey] === true) ? `${fieldKeyLabel[fieldKey]} is required` : `${fieldKeyLabel[fieldKey]} requires ${reference[fieldKey]} but got ${coverage[fieldKey]}`

              validationFailed = true;
              failedReasons.push(
                `${fieldKeyLabel[insuranceType]} does not meet requirement`
                +` - ${error}.`
              )
            }
          })
          // end - process fields that needs to match

          // begin - process limits
          let referenceLimits = reference?.limits
          if(typeof referenceLimits === "string") {
            referenceLimits = JSON.parse(referenceLimits)
          }
          let coverageLimits = coverage.limits || {}
          if(typeof coverageLimits === "string") {
            coverageLimits = JSON.parse(coverageLimits)
          }
          if(referenceLimits && Object.keys(referenceLimits)?.length) {
            if(!Object.keys(coverageLimits)?.length) {
              validationFailed = true;
              failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - no coverage limits are specified.`)
            } else {
              Object.entries(referenceLimits).forEach(([limitKey, limitValue]) => {
                const coverageLimit = coverageLimits[limitKey] || 0;
                if(limitValue > coverageLimit) {
                  validationFailed = true;
                  failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - ${limitKey} is lower than the required limit of $${formatCurrencyInt(limitValue)}.`)
                }
              })
            }
          }
          // end - process limits

          // begin - check if policy is valid
          const policyValidationFailed = validatePolicy(certData, coverage, failedReasons)
          if(policyValidationFailed) {
            validationFailed = true;
          }

          // todo:
          // if(coverage.exclusion) {
            // if exclusion set to true, need to specify who to exclude in description of operation
          // }
          // end - check if policy is valid
        }
      }
    })
  } else if(certData?.coverages?.items?.length) {
    certData.coverages.items.forEach(coverage => {
      if(coverage.insuranceType && non_custom_insurance_types.includes(coverage.insuranceType)) {
        const policyValidationFailed = validatePolicy(certData, coverage, failedReasons)
        if(policyValidationFailed) {
          validationFailed = true;
        }
      }
    })
  }
  return {
    isValid: !validationFailed,
    validationErrors: failedReasons
  }
}

function validatePolicy(certData = {}, coverage = {}, failedReasons = []) {
  let validationFailed = false;
  const {insuranceType} = coverage
  if(!coverage.policyNumber) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy number is missing.`)
  }
  if(
    !coverage.policyEff?.length
    || !coverage.policyExp?.length
    || coverage.policyEff?.length !== coverage.policyExp?.length
  ) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy effective date or expiration date is missing.`)
  } else {
    coverage.policyExp?.forEach(policyExp => {
      if(policyExp && isExpired(policyExp)) {
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - policy expiration date ${awsDateToUS(policyExp)} is expired.`)
      }
    })
  }
  if(!coverage.insurerLetter?.length) {
    validationFailed = true;
    failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - no insurer is specified.`)
  } else {
    coverage.insurerLetter.forEach(insurerLetter => {
      if(!certData[`insurer${String(insurerLetter).toUpperCase()}`]) {
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - insurer ${String(insurerLetter).toUpperCase()} listed but no affiliate name specified.`)
      } else if (!certData[`insurer${String(insurerLetter).toUpperCase()}NAIC`]){
        validationFailed = true;
        failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - insurer ${String(insurerLetter).toUpperCase()} listed but no NAIC specified.`)
      }
    })
  }

  if(coverage.additionalInsured) {
    const insuredName = String(certData.insured).trim().replaceAll("INSURED", "").split("\n")[0]
    if(
      !certData.descriptionOfOperation
      || !certData.insured
      || !String(certData.insured).trim()
      || !String(certData.descriptionOfOperation).toUpperCase().includes(insuredName.toUpperCase())
    ) {
      validationFailed = true;
      failedReasons.push(`${fieldKeyLabel[insuranceType]} does not meet requirement - Named Additional Insured is set to Yes but the insured is not listed in Description of Operation.`)
    }
  }

  return validationFailed;
}
