import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default function LoaderButton(props) {
  const { loading = false, disabled, children } = props;
  return (
    <Button {...props} disabled={!!loading || disabled}>
      {children}
      {loading && (
        <CircularProgress
          color={"inherit"}
          size={24}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Button>
  );
}
