import { isExpired, isLaterDate } from "./dateUtils"
import { Typography } from "@mui/material";
import insurance_types, { coverageFullName, COVERAGE_PROPERTIES } from "constants/insurance_types";
import {formatCurrencyInt} from "utils/formUtils";


export const displayCoveragesProperties = (coverage = {}) => {
  if (coverage?.insuranceType === "GL") {
    return {
      // "Insurance form": coverage?.insuranceForm || [],
      "Additional insured": coverage?.additionalInsured ? "Yes" : "No",
      "Subrogation waived": coverage?.subrogationWaiver ? "Yes" : "No",
      "Aggregate applies per": coverage?.aggregateAppliesPer || [],
    };
  } else if (coverage?.insuranceType === "AU") {
    return {
      Properties: coverage?.coveredProperty || [],
      "Additional insured": coverage?.additionalInsured ? "Yes" : "No",
      "Subrogation waived": coverage?.subrogationWaiver ? "Yes" : "No",
    };
  } else if (coverage?.insuranceType === "UMB") {
    return {
      // "Insurance form": coverage?.insuranceForm || [],
      "Additional insured": coverage?.additionalInsured ? "Yes" : "No",
      "Subrogation waived": coverage?.subrogationWaiver ? "Yes" : "No",
      Deductible: coverage?.deductible,
      Retention: coverage?.retention,
    };
  } else if (coverage?.insuranceType === "WC") {
    return {
      "Limit per statute": coverage?.limitPerStatute,
      "Subrogation waived": coverage?.subrogationWaiver ? "Yes" : "No",
      Exclusion: coverage?.exclusion ? "Yes" : "No",
    };
  } else if (coverage?.insuranceType === "CUSTOM") {
    return {
      "Insurance form": coverage?.insuranceForm || [],
      "Additional insured": coverage?.additionalInsured ? "Yes" : "No",
      "Subrogation waived": coverage?.subrogationWaiver ? "Yes" : "No",
      Deductible: coverage?.deductible,
      Retention: coverage?.retention,
    };
  } else {
    return coverage;
  }
};

export const getCoverageFullName = (insuranceType, name) => {
  return name ?? coverageFullName[insuranceType];
}

export const displayCoverageLimits = (coverage = {}) => {

  if (coverage?.limits) {
    return (

      <li style={{ padding: "10px 0" }}>
        Limits:
        <ul style={{ paddingLeft: "10px" }}>
          {insurance_types[coverage.insuranceType]?.limits.filter(limit => coverage.limits[limit]).map((limit) => {
            return (
              <li key={`limit-${coverage?.insuranceType}-${limit}}`}>
                <Typography fontSize={12} color="text.secondary">
                  {limit}: <b>${formatCurrencyInt(coverage.limits[limit])}</b>
                </Typography>
              </li>
            );
          })}
        </ul>
      </li>
    )
  }
};

export const formatContent = (content) => {
  if (Array.isArray(content)) {
    return content.join(", ");
  } else if (typeof content === "object") {
    return JSON.stringify(content);
  }
  return content;
};


export const emailProjectCoverageRequirementsTemplate = (project, certificateHolder) => {

  const coverages = typeof project?.requiredCoverages === 'string' ? JSON.parse(project?.requiredCoverages|| "[]") : project?.requiredCoverages;

  if (!coverages?.length) {
    return null;
  }

  return `<div style="margin:24px auto;">
      <h3>
        Coverage Requirements:
      </h3>
      <h5>
        Certificate Holder: <span style="background-color:#f3f4f7;">${certificateHolder}</span>
      </h5>
      <table style="border-collapse: collapse;">
    ${coverages.slice(0, 5).map(
      (coverage, index) =>
        `<td style="vertical-align:top;border:1px solid rgba(34,36,38,0.15);">
              <h5 style="background: #f3f4f7;margin:0;">${coverage?.insuranceType}</h5>
              <ul style="list-style-position:inside;padding:0;margin:0;">
                ${Object.entries(displayCoveragesProperties(coverage) || {}).map(([key, value]) => (`<li style="margin:0;">
                  <p style="display:inline-block;font-size:12px;">
                  ${key}: 
                  </p>
                  <b>${formatContent(value)}</b>
                </li>`))}
                </ul>
                ${
                  coverage?.limits &&
                  `
                    <p style="padding:5px;">Limits:</p>
                    <ul style="list-style-position:inside;padding:0;margin:0;">
                      ${Object.entries(coverage?.limits || {}).map(([key, value]) => {
                          return `<li style="margin:0;">
                          <p style="display:inline-block;font-size:12px;">
                            ${key}: 
                            </p>
                            <b>$${formatContent(value)}</b>
                        </li>`
                      })}
                  </ul>`
                }
          </td>`
    )}
  </table>
  <table>
    ${coverages.slice(5, coverages.length).map(
      (coverage, index) =>
        `<td style="vertical-align:top;border:1px solid rgba(34,36,38,0.15);">
              <h5 style="background: #f3f4f7;">${coverage?.insuranceType}</h5>
              <ul style="list-style-position:inside;padding:0;margin:0;">
                ${Object.entries(displayCoveragesProperties(coverage) || {}).map(([key, value]) => (`<li style="margin:0;">
                  <p style="display:inline-block;font-size:12px;">
                  ${key}: 
                  </p>
                  <b>${formatContent(value)}</b>
                </li>`))}
                </ul>
                ${
                  coverage?.limits &&
                  `
                    <p style="padding:5px;">Limits:</p>
                    <ul style="list-style-position:inside;padding:0;margin:0;">
                      ${Object.entries(coverage?.limits || {}).map(([key, value]) => {
                          return `<li style="margin:0;">
                          <p style="display:inline-block;font-size:12px;">
                            ${key}: 
                            </p>
                            <b>$${formatContent(value)}</b>
                        </li>`
                      })}
                    </ul>`
                }
          </td>`
    )}
    </table>
    </div>`.replace(/>,/g, ">"); // eliminate redundant commas (,)
};

export const getAllCoverageErrors = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  const limitsErrors = getCoverageLimitsErrors(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const coveredPropertiesErrors = getCoveredPropertiesError(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const selectableCoverageErrors = getSelectableCoverageErrors(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const aggregateAppliesErrors = getAggregateAppliesErrors(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const additionAndSubrogationErrors = getAdditionAndSubrogationSectionErrors(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const expirationErrors = getPolicyExpErrors(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );
  const deductibleRetentionAndExclusionErrors = getDeductibleRetentionExclusion(
    coverageRequirement,
    referenceValues,
    coverageTypeConfig
  );

  const allCoverageErrors =
    limitsErrors + coveredPropertiesErrors + selectableCoverageErrors + aggregateAppliesErrors + additionAndSubrogationErrors + expirationErrors + deductibleRetentionAndExclusionErrors;
  return allCoverageErrors;
};

const getCoverageLimitsErrors = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let numberOfLimitsErrors = 0;
  let limits = coverageRequirement?.limits || {};
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof limits === "string") {
    limits = JSON.parse(limits);
  }

  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  let referenceLimits = referenceValues?.limits || {};

  if (typeof referenceLimits === "string") {
    referenceLimits = JSON.parse(referenceLimits);
  }

  if (
    !! coverageRequirement?.limitPerStatute && (
    ((referenceValues?.limitPerStatute !== COVERAGE_PROPERTIES.PER_STATUTE.label &&
      (parseFloat(referenceValues?.limitPerStatute) > parseFloat(coverageRequirement?.limitPerStatute) ||
        coverageRequirement?.limitPerStatute?.trim().length == 0 ||
        isNaN(coverageRequirement?.limitPerStatute))) ||
      (referenceValues?.limitPerStatute === COVERAGE_PROPERTIES.PER_STATUTE.label && referenceValues?.limitPerStatute !== coverageRequirement.limitPerStatute)) &&
    !overrides[COVERAGE_PROPERTIES.PER_STATUTE.label])
  ) {
    numberOfLimitsErrors += 1;
  }
    
  coverageTypeConfig.limits.forEach((limit) => {
    if (
      referenceLimits[limit] &&
      !overrides[limit] &&
      (parseFloat(referenceLimits[limit]) > parseFloat((limits[limit] || 0)))
      ) {
        numberOfLimitsErrors += 1;
      }
    });

  return numberOfLimitsErrors;
};

const getCoveredPropertiesError = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let numberOfCoveredPropertiesErrors = 0;
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  coverageTypeConfig?.coveredProperty?.forEach(
    (propertyType, propertyTypeIndex) => {
      const otherValue = coverageRequirement.coveredProperty?.find(
        (oneType) => !coverageTypeConfig.coveredProperty.includes(oneType)
      );
      const otherRequiredValues = referenceValues?.coveredProperty?.filter(
        (oneType) => !coverageTypeConfig.coveredProperty?.includes(oneType)
      );
      const checked =
        propertyType !== "OTHER"
          ? coverageRequirement.coveredProperty?.includes(propertyType)
          : otherValue === "" || !!otherValue;

      if (
        (propertyType !== "OTHER" && !overrides[propertyType] &&
          !checked &&
          referenceValues?.coveredProperty?.includes(propertyType)) ||
        (propertyType === "OTHER" && !overrides["OTHER"] &&
          otherRequiredValues?.length &&
          parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0) )
      ) {
        numberOfCoveredPropertiesErrors += 1;
      }
    }
  );

  return numberOfCoveredPropertiesErrors;
};

const getSelectableCoverageErrors = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let numberOfSelectableCoverageErrors = 0;
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  if (
    !overrides[COVERAGE_PROPERTIES.CLAIMS_MADE.label] &&
    !!coverageTypeConfig.insuranceForm &&
    !coverageRequirement?.insuranceForm?.includes("CLAIMS_MADE") &&
    referenceValues?.insuranceForm?.includes("CLAIMS_MADE")
  ) {
    numberOfSelectableCoverageErrors += 1;
  }

  if (
    !overrides[COVERAGE_PROPERTIES.OCCUR.label] &&
    !!coverageTypeConfig.insuranceForm &&
    !coverageRequirement?.insuranceForm?.includes("OCCUR") &&
    referenceValues?.insuranceForm?.includes("OCCUR")
  ) {
    numberOfSelectableCoverageErrors += 1;
  }
  return numberOfSelectableCoverageErrors;
};

const getAdditionAndSubrogationSectionErrors = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let numberOfAdditionErrors = 0;
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  if (
    !overrides[COVERAGE_PROPERTIES.additionalInsured.label] &&
    !!coverageTypeConfig.additionalInsured &&
    !coverageRequirement?.additionalInsured &&
    referenceValues?.additionalInsured
    ) {
      numberOfAdditionErrors += 1;
    }
    
    if (
    !overrides[COVERAGE_PROPERTIES.subrogationWaiver.label] &&
    !!coverageTypeConfig.subrogationWaiver &&
    !coverageRequirement?.subrogationWaiver &&
    referenceValues?.subrogationWaiver
  ) {
    numberOfAdditionErrors += 1;
  }
  return numberOfAdditionErrors;
};

const getDeductibleRetentionExclusion = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let numberOfErrors = 0;
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  if (
    !!referenceValues?.deductible &&
    (!coverageRequirement?.deductible ||
      referenceValues.deductible > coverageRequirement.deductible)
  ) {
    numberOfErrors += 1;
  }

  if (
    !!referenceValues?.retention &&
    (!coverageRequirement?.retention ||
      referenceValues.retention > coverageRequirement.retention)
  ) {
    numberOfErrors += 1;
  }

  if (
    referenceValues?.exclusion !== undefined &&
    !overrides[COVERAGE_PROPERTIES.exclusion.label] &&
    String(referenceValues?.exclusion) !==
      String(coverageRequirement?.exclusion)
  ) {
    numberOfErrors += 1;
  }
  return numberOfErrors;
};

const getAggregateAppliesErrors = (
  coverageRequirement,
  referenceValues,
  coverageTypeConfig
) => {
  let allAggregateAppliesErrors = 0;
  let overrides = coverageRequirement?.overrides || {};
  
  if (typeof overrides === "string") {
    overrides = JSON.parse(overrides);
  }

  if (coverageTypeConfig?.aggregateAppliesPer && coverageRequirement?.aggregateAppliesPer) {
    coverageTypeConfig.aggregateAppliesPer.forEach(
      (aggregateType, aggregateTypeIndex) => {
        const otherValue = coverageRequirement.aggregateAppliesPer?.find(
          (oneType) => !coverageTypeConfig.aggregateAppliesPer.includes(oneType)
        );
        const otherRequiredValues =
          referenceValues?.aggregateAppliesPer?.filter(
            (aggregateType) =>
              !coverageTypeConfig.aggregateAppliesPer?.includes(aggregateType)
          );
        const checked =
          aggregateType !== "OTHER"
            ? coverageRequirement.aggregateAppliesPer?.includes(aggregateType)
            : otherValue === "" || !!otherValue;
        if (
          (aggregateType !== "OTHER" && !overrides[aggregateType] &&
            !checked &&
            referenceValues?.aggregateAppliesPer?.includes(aggregateType)) ||
          (aggregateType === "OTHER" && !overrides["OTHER"] &&
            !!otherRequiredValues?.length &&
            parseFloat(otherRequiredValues[0]) > (parseFloat(otherValue) || 0))
        ) {
          allAggregateAppliesErrors += 1;
        }
      }
    );
  }

  return allAggregateAppliesErrors;
};

const getPolicyExpErrors = (
  coverageRequirement,
  referenceValues
) => {
  let allPolicyExpErrors = 0;
  [
    ...(coverageRequirement?.policyEff || [""])
  ].map((policyEff, dateIndex) => {
    const policyExp = [...(coverageRequirement?.policyExp || [""])][dateIndex]
    if ((coverageRequirement?.policyExp && coverageRequirement?.policyExp[dateIndex] && !policyEff) ||
    (referenceValues?.startDate && policyEff && isLaterDate(policyEff, referenceValues?.startDate))) {
      allPolicyExpErrors += 1;
    } 
    
    if ((policyExp && isExpired(policyExp)) ||
    (referenceValues?.endDate && policyExp && isLaterDate(referenceValues?.endDate, policyExp))) {
      allPolicyExpErrors += 1;
    } 
  })
  return allPolicyExpErrors;
}