import React from "react";
import {
  Box,
  Typography,
  Divider,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
} from "@mui/material";
import {formatCurrencyInt} from "utils/formUtils";

export default function CoverageLineItemEdit (props) {
  const {
    name="",
    amount = 0,
    onUpdate,
    clientAmount,
    classAmount,
    showHelperText=false,
    showHelperTextAsError=false,
    helperTextEntity = "Default",
    isNameEditable = false,
  } = props

  return (
    <Box sx={{display: "flex", alignItems: "baseline"}}>
      <Typography variant="body1" sx={{ display: "inline", flexGrow: 1, pr: 2 }}>
        {isNameEditable ?
          (<TextField
            variant="standard"
            defaultValue={name}
            onBlur={(e) => {
              onUpdate && onUpdate({
                [e.target.value]: amount
              },name)
            }}
            sx={{ minWidth: 160, display: "inline-flex", flexGrow: 1 }}
          />)
          :
          (<Box sx = {{ pb: "3px" }}>{name}</Box>)
}
        
        <Divider/>
      </Typography>
      <FormControl>
        <TextField
          InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>,}}
          variant="standard"
          defaultValue={formatCurrencyInt(amount)}
          onBlur={(e) => {
            // parse input value to accept only float values with one/none decimal point
            const amount = e.target.value.replace(/[^\d.]|(?<=\..*)\./g, "");
            onUpdate && onUpdate({
              [name]: amount
            })
          }}
          sx={{ minWidth: 160 }}
          className={
            showHelperText ? "coverages-validation-error" : null
          }
        />
        {
          !!showHelperText &&
          <FormHelperText error={showHelperTextAsError}>
            {
              `${helperTextEntity} minimum  ` + (
                (clientAmount || classAmount)
                  ? `$ ${formatCurrencyInt(classAmount || clientAmount)}`
                  : "none"
              )
            }
          </FormHelperText>
        }

      </FormControl>
    </Box>
  )
}
