import React from "react";
import { Button, TextField, Typography, ButtonGroup, Stack, Box, Checkbox, FormControlLabel, Select, MenuItem } from "@mui/material";
import { SubtitleInline, SectionTitle } from "components/shared/Typography";
import { getClientLabel } from "constants/organization_types";

export default function AffiliateTypeOrganizationConfiguration({
  organization = {},
  validationErrors = {},
  editOrganization = false,
  haveUpdates = () => {},
  setEditOrganization = () => {},
  setUpdatedClientLabel = () => {},
  handleCancelUpdateOrganization = () => {},
  handleConfirmUpdateOrganization = () => {},
}) {
  return (
    <Box>
      <SectionTitle sx={{ mb: 2 }}>
        Configuration
        {editOrganization ? (
          <ButtonGroup sx={{ ml: 2 }}>
            <Button onClick={handleCancelUpdateOrganization}>Cancel</Button>
            <Button disabled={!haveUpdates() || Object.entries(validationErrors).length > 0} onClick={handleConfirmUpdateOrganization}>
              Save
            </Button>
          </ButtonGroup>
        ) : (
          <Button onClick={() => setEditOrganization(true)}>Edit</Button>
        )}
      </SectionTitle>
      <Stack spacing={3} direction="column">

        <Stack spacing={10} direction="column">
          <div>
            <SubtitleInline>Client Label:</SubtitleInline>
            {editOrganization ? (
              <TextField
                fullWidth
                required
                variant="standard"
                defaultValue={getClientLabel(organization)}
                onChange={(e) => setUpdatedClientLabel(e.target.value)}
              />
            ) : (
              <Typography variant="body1">{getClientLabel(organization)}</Typography>
            )}
          </div>
        </Stack>

      </Stack>
    </Box>
  );
}
