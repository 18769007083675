import React, {useEffect, useState, useImperativeHandle, useMemo} from "react";
import {Button} from "@mui/material";
import Badge from "@mui/material/Badge";
import Typography from '@mui/material/Typography';
import Done from "@mui/icons-material/Done";
import AddIcon from "@mui/icons-material/Add";
import {Tab, Menu} from "semantic-ui-react";
import {useSelector} from "react-redux";
import {clientClassificationsState} from "redux/clientSlice";
import {client_mode} from "constants/client_properties";
import insurance_types, {non_custom_insurance_types} from "constants/insurance_types";
import CertificateCoverageTypeSection from "./CertificateCoverageTypeSection";
import { getAllCoverageErrors } from "utils/projectCoverageRequirementsUtils";
import InitiallyRequestedProjectCoverages from "./InitiallyRequestedProjectCoverages";

export default React.forwardRef(({
      isEdit=true,
      coverages,
      clientCoverages=[],
      clientMode,
      classifications=[],
      classificationIDKey,
      classificationID,
      projectID,
      initialCoverageRequirements,
      acord25FileDate,
    },
    ref
  ) => {
    const [activeIndex, setActiveIndex] = useState(undefined);
    const [initialCoverageData, setInitialCoverageData] = useState([]);
    const [coverageData, setCoverageData] = useState(initialCoverageData);
    const [isDisplayedInitialCoverages, setIsDisplayedInitialCoverages] = useState(false);
    const [coverageDataToDelete, setCoverageDataToDelete] = useState([]);
    const [references, setReferences] = useState({})
    const clientClassifications = useSelector(clientClassificationsState)

    useImperativeHandle(ref, () => {
        return ({
          getCoverageData: () => coverageData,
          getCoverageDataToDelete: () => coverageDataToDelete,
        })
      }, [coverageData, coverageDataToDelete]);

    useEffect(() => {
      setInitialCoverageData(getInitialCoverageData);
      resetCoverageData()
    }, [])

    //reset coverage data on changed coverages
    useEffect(() => {
      resetCoverageData()
    }, [coverages])

    useMemo(() => {
      const currentCoverages = coverages?.length > 0 ? coverages : initialCoverageData
      if(clientClassifications || clientCoverages?.length) {
        const newReferences = {}

        currentCoverages.forEach((coverageRequirement, coverageIndex) => {
          const {insuranceType} = coverageRequirement
          let clientCoverage = null;
          let classCoverage = null;

          // by default use client default requirement
          if(clientCoverages) {
            clientCoverage = getInsuranceListByTypeOrName(clientCoverages, insuranceType, true)
          }

          // if there are project/category requirement, overwrite client requirement with project/category requirement
          if (classifications?.items?.length && clientClassifications) {
            if (clientMode === client_mode.project) {
              const project = projectID && clientClassifications?.find((classification) => classification.id === projectID);
              if (project?.requiredCoverages) {
                const classCoverages = JSON.parse(project.requiredCoverages || "[]");
                classCoverage = getInsuranceListByTypeOrName(classCoverages, insuranceType, true) || {};
                classCoverage.startDate = project.startDate;
                classCoverage.endDate = project.endDate;
              }
            } else if (clientMode === client_mode.category) {
              const categoryID = classifications.items.find((classification) => classification.primary);
              const category = categoryID && clientClassifications?.find((classification) => classification.id === categoryID);
              if (category?.requiredCoverages) {
                const classCoverages = JSON.parse(category.requiredCoverages || "[]");
                classCoverage = getInsuranceListByTypeOrName(classCoverages, insuranceType, true);
              }
            }
          }
          const referenceCoverage = classCoverage || clientCoverage || {}
          let limits = referenceCoverage.limits
          if(typeof limits === "string") {
            limits = JSON.parse(limits)
          }
          const referenceKey = coverageRequirement.name ? coverageRequirement.name: coverageRequirement.insuranceType
          newReferences[referenceKey] = {...referenceCoverage, limits}
        })
        setReferences(newReferences)
      }
    }, [clientClassifications?.length, coverages?.length, classifications?.items?.length])

    function getInitialCoverageData () {
      return non_custom_insurance_types.map((insuranceType, index) => ({
        insuranceType,
        index,
        ...(
        (classificationIDKey && classificationID)
          ? {[classificationIDKey]: classificationID}
          : {}
      )
      }))
    }

    function handleShowInitialCoverages (show) {
      setIsDisplayedInitialCoverages(show);
    }

    function getInsuranceListByTypeOrName(insuranceList = [], insuranceType, insuranceName) {
      return insuranceList.find((oneCoverage) => (insuranceName && oneCoverage.name === oneCoverage) || oneCoverage.insuranceType === insuranceType);
    }

    function resetCoverageData() {
      const newData = getInitialCoverageData();
        coverages.forEach((coverageRequirement, coverageIndex) => {
          const {insuranceType} = coverageRequirement
          const index = non_custom_insurance_types.indexOf(insuranceType)
          const coverage = {...coverageRequirement, insuranceType, index}
          if(index === -1) {
            coverage.index = newData.length;
            newData.push(coverage)
          } else {
            newData[non_custom_insurance_types.indexOf(insuranceType)] = coverage
          }
        })
        setCoverageData(newData)
        setCoverageDataToDelete([])
    }

    function handleAddInsuranceType() {
      const insuranceType = 'CUSTOM'
      let index = coverageData.length;
      let clientCoverage = {}
      setCoverageData([
        ...coverageData,
        {...clientCoverage, insuranceType, index}
      ])
      setActiveIndex(index)
    }

    function onUpdate({id = null, name, value, index: updateIndex, updates={}}) {
      const updated = coverageData.map(oneCoverage => {
        if(oneCoverage?.index === updateIndex) {
          return {
            ...oneCoverage,
            ...{[name]: value},
            ...updates,
          }
        }
        return oneCoverage
      })
      setCoverageData(updated)
    }

    function onDeleteTab (index) {
      const coverageToDelete = coverageData[index]
      const update = coverageData.filter((_, i) => i !== index).map(oneCoverage => ({
          ...oneCoverage,
          index: oneCoverage.index > index ? oneCoverage.index - 1 : oneCoverage.index
        }));
      setCoverageData(update);
      if (coverageToDelete.id) {
        setCoverageDataToDelete([...coverageDataToDelete, coverageToDelete]);
      }

      setActiveIndex(--index);
    }

    function coverageErrorsIndicator(
      coverageRequirement,
      referenceValues,
      coverageTypeConfig,
      text
    ) {
      const errors = getAllCoverageErrors(
        coverageRequirement,
        referenceValues,
        coverageTypeConfig
      );
      return (
        <>
          {errors > 0 ? (
            <Badge badgeContent={errors} color="error">
              <Typography sx={{ paddingRight: '1rem'}}>
                {text}
              </Typography>
            </Badge>
          ) : (
              <Badge badgeContent={<Done sx={{fontSize: 10, color: "white", fill: 'white', stroke: 'white', strokeWidth: 2}} />} color="success">
              <Typography sx={{ paddingRight: '1rem'}}>
                {text}
              </Typography>
            </Badge>
            
          )}
        </>
      );
    }

    function getPanes() {
      let panes = Array(non_custom_insurance_types.length).fill(false);
      coverageData.forEach((coverageRequirement, tabIndex) => {
        const { insuranceType, index: coverageIndex, name } = coverageRequirement
        const index = non_custom_insurance_types.indexOf(insuranceType)
        const coverageTypeConfig = insurance_types[insuranceType];
        const referenceValues = references[name || insuranceType];
        const pane = {
          menuItem: (
            <Menu.Item key={tabIndex}>
              {index === -1 && coverageRequirement.name
                ?  <Typography sx={{ paddingY: '0.1rem'}}>
                    {String(coverageRequirement.name).substring(0, 3).toUpperCase()}
                  </Typography>
                : coverageErrorsIndicator(
                    coverageRequirement,
                    referenceValues,
                    coverageTypeConfig,
                    coverageRequirement.insuranceType
                  )}
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane style={{ border: "none" }} key={tabIndex}>
              {initialCoverageRequirements && (
                <InitiallyRequestedProjectCoverages
                  coverageIndex={coverageIndex}
                  isOpen={isDisplayedInitialCoverages}
                  setIsOpen={handleShowInitialCoverages}
                  project={clientClassifications?.find((classification) => classification.id === initialCoverageRequirements?.projectID)}
                  initialCoverageRequirements={initialCoverageRequirements}
                  acord25FileDate={acord25FileDate}
                />
              )}
              <CertificateCoverageTypeSection
                index={coverageIndex}
                isEdit={isEdit}
                isCustom={insuranceType === "CUSTOM"}
                coverageTypeConfig={coverageTypeConfig}
                coverageRequirement={coverageRequirement}
                onUpdate={onUpdate}
                onDeleteTab={onDeleteTab}
                clientMode={clientMode}
                referenceValues={referenceValues}
              />
            </Tab.Pane>
          )
      }
        if(index === -1) {
          panes.push(pane)
        } else {
          panes[index] = pane
        }
      })
      if(isEdit) {
        panes.push({
          menuItem: (
            <div>
              <Button
                onClick={handleAddInsuranceType}
                startIcon={<AddIcon />}
                className={"add-insurance-type-button"}
                sx={{ paddingY: '1rem'}}
              >
                Add
              </Button>
            </div>
          ),
          render: () => false
        })
      }
      return panes
    }

    return (
      <Tab
        panes={getPanes()}
        activeIndex={activeIndex}
        onTabChange={()=>setActiveIndex(undefined)}
        menu={{ secondary: true, pointing: true }}
      />
    )
})
