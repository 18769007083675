import React, { useState } from "react";

import { Button, Box, Avatar, Collapse } from "@mui/material";
import { Feed, List } from "semantic-ui-react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function ActivityHistoryInfoSection({ organizationActivities }) {
  const [showActivityDetail, setShowActivityDetail] = useState({ 0: true });
  // Display no activity message in case when there is nothing in activity log
  if (organizationActivities.length < 1) {
    return (
      <Box>
        <span style={{ fontWeight: "600" }}>No Activity History</span>
      </Box>
    )
  }
  return (
    <Box className={"other-info-section"}>
      {!!organizationActivities?.length && (
        <Box sx={{ overflowY: "scroll", maxHeight: "1200px", "&::-webkit-scrollbar": { width: "2px" } }}>
          <Feed>
            {organizationActivities.map((activity, index) => {
              let updates = JSON.parse(activity.field || "{}");
              const nameUpdate = updates.name;
              const vendorLabelUpdate = updates.vendorLabel;
              const projectLabelUpdate = updates.projectLabel;
              const clientLabelUpdate = updates.clientLabel;
              const useDefaultProject = updates.useDefaultProject;
              const usePrimaryNotificationEmailAddress = updates.usePrimaryNotificationEmailAddress;
              const primaryNotificationEmailAddress = updates.primaryNotificationEmailAddress;
              const adminName = updates.adminName;
              const adminEmail = updates.adminEmail;
              const website = updates.website;
              const city = updates.city;
              const state = updates.state;
              const zip = updates.zip;
              const address1 = updates.address1;
              const address2 = updates.address2;
              const phone = updates.phone;
              const tableRowsPerPage = updates.tableRowsPerPage;
              const logoUpdate = updates.logo;
              const userInviteUpdate = updates.user_invited;
              const userDeleteUpdate = updates.user_deleted;
              const userDisableUpdate = updates.user_disabled;
              const userEnableUpdate = updates.user_enabled;
              const emailTemplateUpdate = { templates: updates.templates };
              const clientCoveragesUpdate = { clientCoverages: updates.clientCoverages };
              const updateSections = [];
              let updatedSectionDesc;
              nameUpdate && updateSections.push("name");
              vendorLabelUpdate && updateSections.push("Vendor's Label");
              projectLabelUpdate && updateSections.push("Project's Label");
              clientLabelUpdate && updateSections.push("Client's Label");
              useDefaultProject && updateSections.push("enforce Default Project's coverages");
              usePrimaryNotificationEmailAddress && updateSections.push("enforce Primary Notification Email Address");
              primaryNotificationEmailAddress && updateSections.push("Primary Notification Email Address");
              adminName && updateSections.push("Admin Name");
              adminEmail && updateSections.push("Admin Email Address");
              website && updateSections.push("Website URL");
              city && updateSections.push("City");
              zip && updateSections.push("ZIP");
              state && updateSections.push("State");
              phone && updateSections.push("Phone");
              address1 && updateSections.push("Address 1");
              address2 && updateSections.push("Address 2");
              tableRowsPerPage && updateSections.push("Table Rows Per Page");
              logoUpdate && updateSections.push("logo");
              (userInviteUpdate || userDeleteUpdate || userDisableUpdate || userEnableUpdate) && updateSections.push("user");
              emailTemplateUpdate.templates && updateSections.push("template");
              clientCoveragesUpdate.clientCoverages && updateSections.push("client coverages");

              if (updateSections.length === 1) {
                updatedSectionDesc = updateSections[0];
              } else if (updateSections.length === 2) {
                updatedSectionDesc = `${updateSections[0]} and ${updateSections[1]}`;
              } else {
                updatedSectionDesc = ` : `;
              }

              return (
                <Feed.Event key={"activity-" + index}>
                  <Feed.Label>
                    <Avatar>
                      {String(activity.userFullName)
                        .split(" ")
                        .map((part) => part.charAt(0))
                        .join("")
                        .toUpperCase()}
                    </Avatar>
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Date>{new Date(activity.createdAt).toLocaleString("en-us")}</Feed.Date>
                    <Feed.Summary style={{ display: "flex", alignItems: "baseline" }}>
                      <Box>
                        <span style={{ fontWeight: "600" }}>{activity.userFullName}</span>
                        {activity.action === "CREATE" ? ` created organization` : ` updated organization's ${updatedSectionDesc}`}
                      </Box>
                      <Button
                        size="small"
                        sx={{ ml: "auto", flexShrink: 0 }}
                        onClick={() =>
                          setShowActivityDetail({
                            ...showActivityDetail,
                            [index]: !showActivityDetail[index],
                          })
                        }
                      >
                        {`${!!showActivityDetail[index] ? "Hide" : "Show"} details`}
                      </Button>
                    </Feed.Summary>
                    {!!Object.keys(updates).length && (
                      <Collapse in={!!showActivityDetail[index]}>
                        <Feed.Extra text>
                          <List bulleted={true}>
                            {Object.entries({
                              ...(nameUpdate || {}),
                              ...(vendorLabelUpdate || {}),
                              ...(projectLabelUpdate || {}),
                              ...(useDefaultProject || {}),
                              ...(usePrimaryNotificationEmailAddress || {}),
                              ...(primaryNotificationEmailAddress || {}),
                              ...(adminName || {}),
                              ...(adminEmail || {}),
                              ...(website || {}),
                              ...(city || {}),
                              ...(state || {}),
                              ...(zip || {}),
                              ...(address1 || {}),
                              ...(address2 || {}),
                              ...(phone || {}),
                              ...(tableRowsPerPage || {}),
                              ...(logoUpdate || {}),
                              ...(userInviteUpdate || {}),
                              ...(userDeleteUpdate || {}),
                              ...(userDisableUpdate || {}),
                              ...(userEnableUpdate || {}),
                              ...(userInviteUpdate || {}),
                              ...(userDeleteUpdate || {}),
                              ...(userDisableUpdate || {}),
                              ...(userEnableUpdate || {}),
                              ...(emailTemplateUpdate.templates ? emailTemplateUpdate : {}),
                              ...(clientCoveragesUpdate.clientCoverages ? clientCoveragesUpdate : []),
                            }).map(([fieldName, updates]) => {
                              //user management
                              if (updates?.next?.email) {
                                return (
                                  <List.Item key={`${activity.id}_${fieldName}`}>
                                    <p style={{ textTransform: "capitalize", fontWeight: "600" }}>{fieldName.replace("_", " ")}</p>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {updates.next ? (
                                        <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>
                                          <span style={{ color: "darkgray" }}>Email: </span>
                                          {updates.next?.email} <br />
                                          <span style={{ color: "darkgray" }}>Role: </span> {updates.next?.role}
                                        </p>
                                      ) : (
                                        <p style={{ color: "darkgray" }}>{"blank"}</p>
                                      )}
                                    </Box>
                                  </List.Item>
                                );
                              } else if (fieldName === "templates") {
                                return Object.entries(updates).map(([tempKey, tempVal]) => {
                                  return (
                                    <List.Item key={`${activity.id}_${fieldName}_${tempKey}`}>
                                      <p style={{ textTransform: "capitalize", textDecoration: "underline", fontWeight: "600" }}>{tempKey}</p>
                                      {Object.entries(tempVal).map(([key, val]) => {
                                        return (
                                          <div key={`${activity.id}_${fieldName}_${tempKey}_${key}`}>
                                            <p style={{ textTransform: "capitalize", fontWeight: "600", paddingTop: 20 }}>{key}</p>
                                            <br />
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                              {val.prev ? (
                                                <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{val.prev}</p>
                                              ) : (
                                                <p style={{ color: "darkgray" }}>{"blank"}</p>
                                              )}
                                              <ArrowForwardIcon sx={{ fontSize: 16, ml: 1, mr: 1 }} />
                                              {val.next ? (
                                                <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{val.next}</p>
                                              ) : (
                                                <p style={{ color: "darkgray" }}>{"blank"}</p>
                                              )}
                                            </Box>
                                          </div>
                                        );
                                      })}
                                    </List.Item>
                                  );
                                });
                              } else if (fieldName === "clientCoverages" && Array.isArray(updates)) {
                                return updates.map((update, updateIndex) => {
                                  return Object.entries(update).map(([updateKey, { prev, next }]) => {
                                    if (!prev) {
                                      return (
                                        <List.Item key={`${activity.id}_${fieldName}_${updateIndex}`}>
                                          <p style={{ textTransform: "capitalize", fontWeight: "600" }}>Created {updateKey} coverage</p>
                                        </List.Item>
                                      );
                                    } else if (!next) {
                                      return (
                                        <List.Item key={`${activity.id}_${fieldName}_${updateIndex}`}>
                                          <p style={{ textTransform: "capitalize", fontWeight: "600" }}>Deleted {updateKey} coverage</p>
                                        </List.Item>
                                      );
                                    } else {
                                      return (
                                        <List.Item key={`${activity.id}_${fieldName}_${updateIndex}`}>
                                          <p style={{ textTransform: "capitalize", fontWeight: "600" }}>{updateKey} coverage</p>
                                          {Object.entries(prev).map(([key, value]) => {
                                            return (
                                              <div key={`${activity.id}_${fieldName}_${key}`}>
                                                <p style={{ textTransform: "capitalize", fontWeight: "600", paddingTop: 20 }}>
                                                  {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                                </p>
                                                <br />
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                  {value ? (
                                                    <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{JSON.stringify(value)}</p>
                                                  ) : (
                                                    <p style={{ color: "darkgray" }}>{"blank"}</p>
                                                  )}
                                                  <ArrowForwardIcon sx={{ fontSize: 16, ml: 1, mr: 1 }} />
                                                  {next[key] ? (
                                                    <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{JSON.stringify(next[key])}</p>
                                                  ) : (
                                                    <p style={{ color: "darkgray" }}>{"blank"}</p>
                                                  )}
                                                </Box>
                                              </div>
                                            );
                                          })}
                                        </List.Item>
                                      );
                                    }
                                  });
                                });
                              } else {
                                return (
                                  <List.Item key={`${activity.id}_${fieldName}`}>
                                    <p style={{ textTransform: "capitalize", fontWeight: "600" }}>{fieldName}</p>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                      {updates.prev ? (
                                        <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{updates.prev}</p>
                                      ) : (
                                        <p style={{ color: "darkgray" }}>{"blank"}</p>
                                      )}
                                      <ArrowForwardIcon sx={{ fontSize: 16, ml: 1, mr: 1 }} />
                                      {updates.next ? (
                                        <p style={{ flexBasis: "50%", flexGrow: 0, overflowWrap: "anywhere" }}>{updates.next}</p>
                                      ) : (
                                        <p style={{ color: "darkgray" }}>{"blank"}</p>
                                      )}
                                    </Box>
                                  </List.Item>
                                );
                              }
                            })}
                          </List>
                        </Feed.Extra>
                      </Collapse>
                    )}
                  </Feed.Content>
                </Feed.Event>
              );
            })}
          </Feed>
        </Box>
      )}
    </Box>
  );
}
