import React from "react";
import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Divider
} from "@mui/material";
import {SubtitleInline} from "components/shared/Typography";

export default function CertificateInfoSection ({ certificateData, handleCertificateInfoUpdate, selectedClient, vendor, isEdit = true }) {
  const isEqualFirstRowToInput = (textFieldInput, validationInput) => {
    return textFieldInput?.split('\n')[0]?.toUpperCase()?.trim() === validationInput?.toUpperCase()?.trim();
  }

  const isValidInsured = isEqualFirstRowToInput(certificateData?.insured, vendor?.name)
  const isValidCertificateHolder = isEqualFirstRowToInput(certificateData?.certificateHolder, selectedClient?.name)
  
  return (
    <Stack>
      <Stack direction="row" mt={2} mb={2} spacing={2}>
        <Stack item sx={{flexBasis: "50%", justifyContent: "space-between"}}>
          <Box>
            <SubtitleInline>INSURED</SubtitleInline>
            <TextField
              key={certificateData?.insured ? "insured" : "insuredEmpty"}
              name="insured"
              defaultValue={certificateData?.insured ? String(certificateData.insured).trim() : ""}
              variant="outlined"
              fullWidth
              onChange={handleCertificateInfoUpdate}
              multiline
              rows={6}
              error={!isValidInsured}
              disabled={!isEdit}
              helperText={!isValidInsured ? `INSURED should match SUBCONTRACTOR "${vendor?.name}" ` : null}
              className={!isValidInsured ? "display-error-line-1" : null}
            />
          </Box>
        </Stack>
        <Stack item sx={{flexBasis: "50%", justifyContent: "space-between"}}>
          <Box>
            <SubtitleInline>CERTIFICATE HOLDER</SubtitleInline>
            <TextField
              key={certificateData?.certificateHolder ? "certificateHolder" : "certificateHolderEmpty"}
              name="certificateHolder"
              defaultValue={certificateData?.certificateHolder || ""}
              variant="outlined"
              fullWidth
              onChange={handleCertificateInfoUpdate}
              multiline
              rows={6}
              error={!isValidCertificateHolder}
              disabled={!isEdit}
              helperText={!isValidCertificateHolder ? `CERTIFICATE HOLDER should match CLIENT "${selectedClient?.name}" ` : null}
              className={!isValidCertificateHolder ? "display-error-line-1" : null}
            />
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" mt={2} mb={4} spacing={2}>
        <Stack item sx={{flexBasis: "50%", justifyContent: "space-between"}}>
          <Box>
            <SubtitleInline>PRODUCER</SubtitleInline>
            <TextField
              key={certificateData?.producer ? "producer" : "producerEmpty"}
              name="producer"
              defaultValue={certificateData?.producer ? String(certificateData.producer).trim() : ""}
              variant="outlined"
              fullWidth
              onBlur={handleCertificateInfoUpdate}
              multiline
              rows={6}
              disabled={!isEdit}
            />
          </Box>
        </Stack>
        <Stack item sx={{flexBasis: "50%"}}>
          <SubtitleInline>PRODUCER CONTACT</SubtitleInline>
          <TextField
            key={certificateData?.producerContactName ? "producerContactName" : "producerContactNameEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">NAME</InputAdornment>,
            }}
            name="producerContactName"
            defaultValue={certificateData?.producerContactName || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerPhone ? "producerPhone" : "producerPhoneEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">PHONE</InputAdornment>,
            }}
            name="producerPhone"
            defaultValue={certificateData?.producerPhone || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerFax ? "producerFax" : "producerFaxEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">FAX</InputAdornment>,
            }}
            name="producerFax"
            defaultValue={certificateData?.producerFax || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
          <TextField
            key={certificateData?.producerEmail ? "producerEmail" : "producerEmailEmpty"}
            InputProps={{
              startAdornment: <InputAdornment position="start">EMAIL</InputAdornment>,
            }}
            name="producerEmail"
            defaultValue={certificateData?.producerEmail || ""}
            variant="standard"
            fullWidth
            onBlur={handleCertificateInfoUpdate}
            disabled={!isEdit}
          />
        </Stack>
      </Stack>
      <Divider/>
      <Stack mt={4} mb={2}>
        <Stack direction="row">
          <SubtitleInline sx={{flexBasis: "70%"}}>INSURER(S) AFFORDING COVERAGE</SubtitleInline>
          <SubtitleInline sx={{flexBasis: "30%"}}>NAIC #</SubtitleInline>
        </Stack>

        {
          ["A", "B", "C", "D", "E", "F"].map(insurerLetter => (
            <Stack direction="row" key={"insurerLetter-"+insurerLetter}>
              <TextField
                key={certificateData?.["insurer" + insurerLetter] ? ("insurerLetter" + insurerLetter) : ("insurerLetterEmpty" + insurerLetter)}
                name={"insurer" + insurerLetter}
                defaultValue={certificateData?.["insurer" + insurerLetter] || ""}
                variant="standard"
                fullWidth
                onBlur={handleCertificateInfoUpdate}
                InputProps={{
                  startAdornment: <InputAdornment position="start">{`INSURER ${insurerLetter}:`}</InputAdornment>,
                }}
                sx={{flexBasis: "70%"}}
                disabled={!isEdit}
              />
              <TextField
                key={certificateData?.["insurer" + insurerLetter + "NAIC"] ? ("insurer" + insurerLetter + "NAIC") : ("insurerEmpty" + insurerLetter + "NAIC")}
                name={"insurer" + insurerLetter + "NAIC"}
                defaultValue={certificateData?.["insurer" + insurerLetter + "NAIC"] || ""}
                variant="standard"
                fullWidth
                onBlur={handleCertificateInfoUpdate}
                sx={{flexBasis: "30%", ml: 2}}
                disabled={!isEdit}
              />
            </Stack>
          ))
        }
      </Stack>
      <Stack mt={2}>
        <SubtitleInline>DESCRIPTION OF OPERATION</SubtitleInline>
        <TextField
          key={certificateData?.descriptionOfOperation ? "descriptionOfOperation" : "descriptionOfOperationEmpty"}
          sx={{mt:1}}
          fullWidth
          multiline
          rows={8}
          placeholder={"Add description of operation ..."}
          name="descriptionOfOperation"
          defaultValue={certificateData?.descriptionOfOperation}
          onBlur={handleCertificateInfoUpdate}
          disabled={!isEdit}
        />
      </Stack>
    </Stack>
  )
}
