import React, {useEffect, useState, useImperativeHandle, useMemo} from "react";
import {Tab, Menu} from "semantic-ui-react";
import insurance_types, {non_custom_insurance_types} from "constants/insurance_types";
import CoverageTypeSection from "./CoverageTypeSection";
import AddIcon from "@mui/icons-material/Add";
import {Button} from "@mui/material";

export default React.forwardRef(({
  open=false,
  isEdit=false,
  coverages,
  clientCoverages=[],
  classCoverages=[],
  clientMode,
  showHelperText=false,
  vendorCoverages=[],
  classifications=[],
  classificationIDKey,
  classificationID,
  refresh=0
}, ref) => {

  const [activeIndex, setActiveIndex] = useState(undefined)
  const [coverageData, setCoverageData] = useState(getInitialCoverageData())
  const [references, setReferences] = useState({})

  useImperativeHandle(ref, () => ({
    getCoverageData: () => coverageData
  }), [coverageData]);

  useEffect(() => {
    resetCoverageData(isEdit)
  }, [])

  useEffect(() => {
    if(!open && !!isEdit) {
      setCoverageData(getInitialCoverageData())
    }
  }, [open])

  useMemo(() => {
    if(!isEdit && !!refresh) {
      resetCoverageData()
    }
  }, [refresh])

  function getInitialCoverageData() {
    return non_custom_insurance_types.map((insuranceType, index) => ({
      insuranceType,
      index,
      ...(
        (classificationIDKey && classificationID)
          ? {[classificationIDKey]: classificationID}
          : {}
      )
    }))
  }

  function getInsuranceListByType(insuranceList=[], insuranceType, isFind=false) {
    const method = isFind ? "find" : "filter"
    return insuranceList[method](oneCoverage => oneCoverage.insuranceType === insuranceType)
  }

  function resetCoverageData(shouldResetReference = false) {
    if(coverages?.length) {
      const newReferences = {}
      const newData = getInitialCoverageData()
      coverages.forEach((coverageRequirement, coverageIndex) => {
        const {insuranceType} = coverageRequirement
        const index = non_custom_insurance_types.indexOf(insuranceType)
        const coverage = {...coverageRequirement, index: coverageIndex, insuranceType}
        if(index === -1) {
          newData.push(coverage)
        } else {
          newData[index] = coverage
        }
        if(shouldResetReference) {
          newReferences[coverageIndex] = {}
          newReferences[coverageIndex]["clientCoverage"] = getInsuranceListByType(clientCoverages, insuranceType, true)
          newReferences[coverageIndex]["classCoverage"] = getInsuranceListByType(classCoverages, insuranceType, true)
          newReferences[coverageIndex]["vendorCoverage"] = getInsuranceListByType(vendorCoverages, insuranceType)
            ?.map(oneCoverage => {
              if (oneCoverage[classificationIDKey]) {
                const vendorClassification = classifications.find(
                  oneClassification => oneClassification.id === oneCoverage[classificationIDKey]
                )
                return {
                  ...oneCoverage,
                  classificationName: vendorClassification?.name || "Vendor Default"
                }
              }
            })
        }
      })
      setCoverageData(newData)
      shouldResetReference && setReferences(newReferences)
    }
  }

  function handleAddInsuranceType () {
    console.log('addInsuranceType')
    const insuranceType = 'CUSTOM'
    let index = coverageData.length;
    let clientCoverage = {}
    setCoverageData([
      ...coverageData,
      {...clientCoverage, insuranceType, index}
    ])
    setActiveIndex(index)
  }

  function onUpdate ({ id = null, name, value, index: updateIndex }) {
    const updated = coverageData.map(oneCoverage => {
      if(oneCoverage.index === updateIndex) {
        return {
          ...oneCoverage,
          [name]: value,
        }
      }
      return oneCoverage
    })
    setCoverageData(updated)
  }

  function onDeleteTab(index) {
    const update = coverageData.filter((_, i) => i !== index).map(oneCoverage => ({
    ...oneCoverage,
    index: oneCoverage.index > index ? oneCoverage.index - 1 : oneCoverage.index
    }));
    setCoverageData(update);
    setActiveIndex(--index);
    }

  function getPanes() {
    let panes = Array(non_custom_insurance_types.length).fill(false);
    coverageData.forEach((coverageRequirement, tabIndex) => {
      const {insuranceType, index: coverageIndex} = coverageRequirement
      const index = non_custom_insurance_types.indexOf(insuranceType)

      const pane = {
        menuItem: (
          <Menu.Item key={tabIndex}>
            {(index === -1 &&coverageRequirement.name) ? String(coverageRequirement.name).substring(0,3).toUpperCase() : coverageRequirement.insuranceType}
          </Menu.Item>
        ),
        render: () => (
          <Tab.Pane style={{border: "none"}} key={tabIndex}>
            <CoverageTypeSection
              index={coverageIndex}
              isEdit={isEdit}
              isCustom={insuranceType === "CUSTOM"}
              coverageTypeConfig={insurance_types[insuranceType]}
              coverageRequirement={coverageRequirement}
              onUpdate={onUpdate}
              onDeleteTab={onDeleteTab}
              clientMode={clientMode}
              showHelperText={showHelperText}
              clientCoverage={references[coverageIndex] && references[coverageIndex].clientCoverage}
              classCoverage={references[coverageIndex] && references[coverageIndex].classCoverage}
              vendorCoverage={references[coverageIndex] && references[coverageIndex].vendorCoverage}
            />
          </Tab.Pane>
        )
      }
      if(index === -1) {
        panes.push(pane)
      } else {
        panes[index] = pane
      }
    })
    if(isEdit) {
      panes.push({
        menuItem: (
          <div>
            <Button
              onClick={handleAddInsuranceType}
              startIcon={<AddIcon />}
            >
              Add Insurance Type
            </Button>
          </div>
        ),
        render: () => false
      })
    }
    return panes
  }

  return (
    <Tab
      panes={getPanes()}
      activeIndex={activeIndex}
      onTabChange={()=>setActiveIndex(undefined)}
      menu={{ secondary: true, pointing: true }}
    />
  )
})
