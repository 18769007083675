import React, { useState } from "react";
import { Button, Box, Menu, MenuItem, Typography } from "@mui/material";

import Modal from "components/shared/Modal";
import LoaderButton from "../shared/LoaderButton";
import StatusIndicator from "../shared/StatusIndicator";
import { Certificatestatus } from "models";

export default function CertificatesActionModal({
  isOpen = false,
  loader = false,
  selectedCertificates = {},
  onUpdateHandler = () => {},
  onCancel = () => {},
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedCertificatesStatus, setSelectedCertificatesStatus] = useState("");

  const numberOfCertificates = Object.keys(selectedCertificates).length;

  return (
    <Modal
      open={isOpen}
      title={"Certificate Actions"}
      content={
        <>
          <Typography variant="body1">
            Update {numberOfCertificates} {numberOfCertificates === 1 ? "certificate" : "certificates"}{" "}
          </Typography>
          <hr />
          <Box my={2}>
            <Typography variant="button">Select New Status : </Typography>
            <StatusIndicator
              label={selectedCertificatesStatus || "No Status"}
              onClick={handleClick}
              // icon={<KeyboardArrowDownIcon />}
            />
            <Menu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem onClick={() => setSelectedCertificatesStatus(Certificatestatus.REVIEW)}>
                <StatusIndicator label={"Review"} />
              </MenuItem>
              <MenuItem onClick={() => setSelectedCertificatesStatus(Certificatestatus.INACTIVE)}>
                <StatusIndicator label={"Inactive"} />
              </MenuItem>
              <MenuItem onClick={() => setSelectedCertificatesStatus(Certificatestatus.APPROVED)}>
                <StatusIndicator label={"Approved"} />
              </MenuItem>
              <MenuItem onClick={() => setSelectedCertificatesStatus(Certificatestatus.NOT_APPROVED)}>
                <StatusIndicator label={"Not Approved"} />
              </MenuItem>
              <MenuItem onClick={() => setSelectedCertificatesStatus(Certificatestatus.EXPIRED)}>
                <StatusIndicator label={"Expired"} />
              </MenuItem>
            </Menu>
          </Box>
        </>
      }
      actions={
        <Box>
          <Button
            disabled={loader}
            onClick={() => {
              setSelectedCertificatesStatus(null);
              onCancel(false);
            }}
          >
            Cancel
          </Button>
          <LoaderButton
            disabled={!selectedCertificatesStatus}
            variant={"contained"}
            onClick={() => {
              onUpdateHandler({
                status: selectedCertificatesStatus,
              });
              setSelectedCertificatesStatus("");
            }}
            loading={loader ? "true" : undefined}
          >
            Update
          </LoaderButton>
        </Box>
      }
    />
  );
}
