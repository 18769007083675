/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://4bw0k31pri.execute-api.us-east-2.amazonaws.com/manacert",
            "region": "us-east-2"
        },
        {
            "name": "FileUploadApi",
            "endpoint": "https://23jqtfvp1a.execute-api.us-east-2.amazonaws.com/manacert",
            "region": "us-east-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://crsvc3jn2jc7zfvztpb7umfcwq.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-c4w6j4srvvhzfdqhrzmf2vg77y",
    "aws_cognito_identity_pool_id": "us-east-2:8012a3f2-f4a1-4644-9a14-aa3d3173bfe3",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_lzYFMWtYo",
    "aws_user_pools_web_client_id": "63rl6ag69aolr67ncvrikds8qd",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "coiguard-storage-68bff28f155354-manacert",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
