export const SYSTEM = "SYSTEM";
export const AGENCY = "AGENCY";
export const CLIENT = "CLIENT";

export default {
  SYSTEM,
  AGENCY,
  CLIENT,
};

export function getHighestOrgType(organizationType) {
  return [SYSTEM, AGENCY, CLIENT].find((routeKey) => organizationType?.includes(routeKey));
}

export function getVendorLabel(organization) {
  return organization?.vendorLabel ?? "Subcontractor";
}

export function getProjectLabel(organization) {
  return organization?.projectLabel ?? "Project";
}

export function getClientLabel(organization) {
  return organization?.clientLabel ?? "Client";
}

export function getTableRowsPerPage(organization) {
  return organization?.tableRowsPerPage ?? 5;
}
